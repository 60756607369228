import API from '../../utils/API'

let nurseBooking = {
    category: 'nurse',
    is_someone_else: true,
    booker_info: {
        user: null,
        name: null,
        gender: null,
        id_card_type: null,
        id_card_number: null,
        id_card_front_file: null,
        id_card_back_file: null,
        religion: null,
        race: null,
        phone: null,
        alt_phone: null,
        email: null
    },
    patient_info: {
        name: null,
        gender: null,
        id_card_type: null,
        id_card_number: null,
        id_card_front_file: null,
        id_card_back_file: null,
        religion: null,
        race: null,
        phone: null,
        alt_phone: null,
        email: null,
        address: {
            lastname: null,
            city: null,
            zipcode: null,
            phone: null,
            province: null,
            country: null,
            firstname: null,
            company: null,
            address1: null,
            address2: null,
            location_name: null
        },
        relationship: null,
        weight: 0,
        height: 0,
        patient_diagnosis: null,
        has_allergies: null,
        allergies: null,
        has_past_surgical_history: null,
        has_past_medical_history: null
    },
    type_of_services: [],
    additional_treatments: [],
    medical_supplies: [],
    assessments: [],
    gender_requirement: null,
    language_requirement: null,
    schedule_start_datetime: undefined,
    schedule_end_datetime: undefined,
    schedule_mode: 'default',
}
export default {
    state: {
        nurseBooking: localStorage.getItem('nurseBooking') ? JSON.parse(localStorage.getItem('nurseBooking')) : nurseBooking, 
        typeOfServicesPreloadData: localStorage.getItem('nurseBooking_typeOfServices') ? JSON.parse(localStorage.getItem('nurseBooking_typeOfServices')) : null,
        additionalTreatmentsPreloadData: localStorage.getItem('nurseBooking_additionalTreatments') ? JSON.parse(localStorage.getItem('nurseBooking_additionalTreatments')) : null,
        assessmentsPreloadData: localStorage.getItem('nurseBooking_assessments') ? JSON.parse(localStorage.getItem('nurseBooking_assessments')) : null,
    },
    getters: {
        nurseBooking: state => state.nurseBooking,
        typeOfServicesPreloadData: state => state.typeOfServicesPreloadData,
        additionalTreatmentsPreloadData: state => state.additionalTreatmentsPreloadData,
        assessmentsPreloadData: state => state.assessmentsPreloadData
    },
    mutations: {
        setCategory(state, payload){
            state.nurseBooking.category = payload.category
            //save the data at localstorage
            localStorage.setItem('nurseBooking', JSON.stringify(state.nurseBooking))
        },
        setDetails(state, payload){
            state.nurseBooking.is_someone_else = payload.is_someone_else
            state.nurseBooking.booker_info = payload.booker_info
            state.nurseBooking.patient_info = payload.patient_info
            //save the data at localstorage
            localStorage.setItem('nurseBooking', JSON.stringify(state.nurseBooking))
        },
        setAssessment(state, payload){
            state.nurseBooking.type_of_services = payload.type_of_services
            state.nurseBooking.additional_treatments = payload.additional_treatments
            state.nurseBooking.medical_supplies = payload.medical_supplies
            state.nurseBooking.assessments = payload.assessments
            state.nurseBooking.gender_requirement = payload.gender_requirement
            state.nurseBooking.language_requirement = payload.language_requirement
            state.nurseBooking.schedule_start_datetime = payload.schedule_start_datetime
            state.nurseBooking.schedule_end_datetime = payload.schedule_end_datetime
            state.nurseBooking.schedule_mode = payload.schedule_mode
            //save the data at localstorage
            localStorage.setItem('nurseBooking', JSON.stringify(state.nurseBooking))
        },
        setTypeOfServicesPreloadData(){

        },
        setAdditionalTreatmentsPreloadData(){

        },
        setAssessmentsPreloadData(){

        },
        setDraftInvoice(state, payload){

        }
    },
    actions: {
        settingUpAllData({commit}, payload){
            //setting all items at mounted section
            //get the data from state
            //check the assessments services data whether there are null or not.
            //if null, then get the data from API
            //then put it properly at related properties in nurseBooking data

            //save the data at localstorage
            localStorage.setItem('nurseBooking', JSON.stringify(state.nurseBooking))
            localStorage.setItem('nurseBooking_typeOfServices', JSON.stringify(state.nurseBooking_typeOfServices))
            localStorage.setItem('nurseBooking_additionalTreatments', JSON.stringify(state.nurseBooking_additionalTreatments))
            localStorage.setItem('nurseBooking_assessments', JSON.stringify(state.nurseBooking_assessments))
        },
        proceedToSummary({commit}, payload){
            //get all the nureBooking data at masage it to be invoice
        },
        proceedToConfirmedInvoice({commit}, payload){
            //get all the nureBooking data at masage it to be real invoice
            //then go to API.
        }
    }
}