import API from './API'

export default (to, from, next) => {
    API.get('/application-setting')
        .then(res => {
            // //Checking maintenance mode
            // if(res.data.is_maintenance && to.path != '/under-maintenance') next('/under-maintenance')
            // else if(!res.data.is_maintenance && to.path == '/under-maintenance') next('/')
            // else next()

            //for now just make maintenance for /store sahaja
            if(res.data.is_maintenance && to.path.includes('/store') && to.path !== '/store/home-nursing/apply') 
                next('/')
            else next()
        })
        .catch(err => {
            //no record has been created yet
            if(err.response.status == 404)
                next()

            
            // //if request failed, means there is a problem with the backend server
            // //hence, redirect to specific page
            // if(err.request){
            //     console.log('yeah')
            //     next('/under-maintenance')
            // }
            // //no record has been created yet
            // if(err.response){
            //     if(err.response.status == 404)
            //     next()
            // }
        })
}