import API from '../../utils/API'
const Swal = require('sweetalert2')

export default {
    state: {
        wishlist: localStorage.getItem('wishlist') ? JSON.parse(localStorage.getItem('wishlist')) : { items: [], wishlist_id: null }, 
    },
    getters: {
        wishlist: state => state.wishlist,
    },
    mutations: {
        submitWishlistItem(state, payload){
            const item = state.wishlist.items.find(i => i.product.id == payload.product.id)
            const index = state.wishlist.items.findIndex(i => i.product.id == payload.id)
            API.get(`/products/${payload.product.id}`)
                .then(resProd => {
                    if(!item) {
                        state.wishlist.items.push({
                            product: resProd.data
                        })
                    } else {
                        state.wishlist.items.splice(index, 1)
                    }
        
                    localStorage.setItem('wishlist', JSON.stringify(state.wishlist))
                    
                    //submit wishlist
                    if(state.wishlist.wishlist_id) {
                        let newWishlist = { item: [], id: state.wishlist.wishlist_id }
                        state.wishlist.items.forEach(item => {
                            newWishlist.item.push({
                                product: item.product.id
                            })
                        })
                        API.put(`/wishlists/${state.wishlist.wishlist_id}`, newWishlist)
                    }
                })
        },
    },
    actions: {
        // just action for
        // Function for generate something, like Excel pdf n so on.
        // Upload & Retrieve file upload function.
    }
}