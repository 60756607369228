import API from '../../utils/API'
import cookies from 'vue-cookies'
import cart from './cart'

export default {
    state: {
        // currentCustomer: cookies.get('session-customer-auth') != null ? cookies.get('session-customer-auth') : null,
        currentCustomer: localStorage.getItem('session-customer-auth') != null ? JSON.parse(localStorage.getItem('session-customer-auth')) : null,
        authError: null,
        authSuccess: false,
        authProcessing: false,
    },
    getters: {
        currentCustomer: state => state.currentCustomer,
        authProcessing: state => state.authProcessing,
        authError: state => state.authError,
        authSuccess: state => state.authSuccess,
    },
    mutations: {
        setCustomer(state, payload) {
            state.currentCustomer = payload
            state.authProcessing = false
            state.authError = null
        },
        setAuthProcessing(state, payload) {
            state.authProcessing = payload
            state.authError = null
        },
        setError(state, payload) {
            state.authError = payload
            state.currentCustomer = null
            state.authProcessing = false
        },
        clearError(state) {
            state.authError = null
        },
        setSuccess(state) {
            state.authSuccess = true
            state.authProcessing = false
        },
        clearSuccess(state) {
            state.authSuccess = false
        }
    },
    actions: {
        async signup({ commit }, payload) {
            commit('clearSuccess')
            commit('clearError')
            commit('setAuthProcessing', true)

            return new Promise(async (resolve, reject) => {
                //validate NRIC first
                const resNRIC = await API.post('/users/validate-user-nric', {nric: payload.nric})
                if(!resNRIC.data.success)
                    return commit('setError', resNRIC.data.msg)

                API.authenticate_register(payload)
                    .then(async res => {
                        await API.get(`/custom/user/${res.data.user.id}/create-customer-relation?nric=${payload.nric}&fullname=${payload.username}`)
                        resolve(window.location.href = '/auth/login?registered=true')
                        //create relation

                        // //create relation
                        // await API.get(`/custom/user/${res.data.user.id}/create-customer-relation`)
                        // //then re-login (get new data)
                        // let data2send = {
                        //     identifier: payload.email,
                        //     password: payload.password
                        // }
                        // let newRes = await API.authenticate(data2send)

                        // var user = { token: newRes.data.jwt, user: newRes.data.user }
                        // // cookies.set('session-customer-auth', user)
                        // localStorage.setItem('session-customer-auth', JSON.stringify(user))
                        // commit('setCustomer', { token: newRes.data.jwt, user: newRes.data.user })

                        // //resetting customer to update customer's credentials
                        // await API.put(`/customers/${newRes.data.user.customer.id}`, {
                        //     nric: payload.nric
                        // })
                        // user.user.customer.nric = payload.nric
                        // localStorage.setItem('session-customer-auth', JSON.stringify(user))
                        // commit('setCustomer', user)

                        // //set cart_id to the customer
                        // let cartData
                        // if (localStorage.getItem('cart')) {
                        //     cartData = JSON.parse(localStorage.getItem('cart'))
                        //     cartData.cart_id = newRes.data.user.customer.cart
                        // } else cartData = { items: [], discount_code: null, cart_id: newRes.data.user.customer.cart }
                        // localStorage.setItem('cart', JSON.stringify(cartData))

                        // //set wish_id to the customer
                        // let wishData
                        // if (localStorage.getItem('wishlist')) {
                        //     wishData = JSON.parse(localStorage.getItem('wishlist'))
                        //     wishData.wishlist_id = newRes.data.user.customer.wishlist
                        // } else wishData = { items: [], wishlist_id: newRes.data.user.customer.wishlist }
                        // localStorage.setItem('wishlist', JSON.stringify(wishData))

                        // //redirect to the main page
                        // if (payload.redirectto && payload.redirectto == 'booking') resolve(window.location.href = '/home-nursing/booking')
                        // else if (payload.redirectto && payload.redirectto == 'noredirect') resolve('')
                        // else resolve(window.location.href = '/')
                    }).catch(err => {
                        if (err.response) {
                            if (err.response.status == 400) {
                                commit('setError', 'user with this email already exists.')
                                // commit('setError', err.response.data.data[0].messages[0].message)
                                reject(err.response.data)
                                // commit('setError', err.response.data.message[0].messages[0].message)
                            }
                        } else
                            commit('setError', err.message)
                        // reject(err.response.data.data[0].messages[0].message)
                        // // cookies.remove('session-customer-auth')
                        // localStorage.removeItem('session-customer-auth')
                        // if (err.response) {
                        //     if (err.response.status == 400) {
                        //         console.log('err : ', err.response)
                        //         commit('setError', err.response.data.message[0].messages[0].message)
                        //         reject(err.response.data.message[0].messages[0].message)
                        //     }
                        // } else
                        //     commit('setError', err.message)
                        // reject(err.message)
                        // // setTimeout(() => commit('clearError'), 5000)
                    })
            })
        },
        login({ commit }, payload) {
            commit('clearSuccess')
            commit('clearError')
            commit('setAuthProcessing', true)

            let data2send = {
                identifier: payload.email,
                password: payload.password
            }

            API.authenticate(data2send)
                .then(async res => {
                    var user = { token: res.data.jwt, user: res.data.user }
                    // cookies.set('session-customer-auth', user)
                    localStorage.setItem('session-customer-auth', JSON.stringify(user))
                    commit('setCustomer', { token: res.data.jwt, user: res.user })

                    if (!res.data.user.customer.cart || !res.data.user.customer.wishlist) {
                        let userRenewRes = await API.get(`/custom/customer/${res.data.user.customer.id}/revalidate-customer-validation`)
                        user.user = userRenewRes.data
                        // console.log('user res data : ', userRenewRes)
                        // console.log('user data : ', user)

                        //reset cookies & state
                        // cookies.set('session-customer-auth', user)
                        localStorage.setItem('session-customer-auth', JSON.stringify(user))
                        commit('setCustomer', { token: res.data.jwt, user: res.user })
                    }

                    //get the customer cart & set it to localstorage
                    let cartRes = await API.get(`/carts/${user.user.customer.cart}`)
                    let cartData = { items: [], discount_code: null, cart_id: user.user.customer.cart }
                    cartRes.data.item.forEach(item => {
                        cartData.items.push({
                            product: item.product,
                            quantity: item.quantity
                        })
                    })
                    let wishlistRes = await API.get(`/wishlists/${user.user.customer.wishlist}`)
                    let wishlistData = { items: [], wishlist_id: user.user.customer.wishlist }
                    wishlistRes.data.item.forEach(item => {
                        wishlistData.items.push({
                            product: item.product
                        })
                    })
                    localStorage.setItem('cart', JSON.stringify(cartData))
                    localStorage.setItem('wishlist', JSON.stringify(wishlistData))

                    //redirect to the main page
                    if (payload.redirectto && payload.redirectto == 'booking') window.location.href = '/home-nursing/booking'
                    else if (payload.redirectto && payload.redirectto == 'eprescription') window.location.href = '/e-prescription'
                    else if (payload.redirectto) window.location.href = payload.redirectto
                    else window.location.href = '/'
                }).catch(err => {
                    // cookies.remove('session-customer-auth')
                    localStorage.removeItem('session-customer-auth')
                    if (err.response) {
                        if (err.response.status == 400) {
                            commit('setError', 'Incorret email and password.')
                            // commit('setError', err.response.data.message[0].messages[0].message)
                        }
                    } else
                        commit('setError', err.message)

                    // setTimeout(() => commit('clearError'), 5000)
                })
        },
        async loginWithSelangkahSSO({ commit }, payload) {
            var user = { token: payload.jwtToken, user: payload.user }
            // cookies.set('session-customer-auth', user)
            localStorage.setItem('session-customer-auth', JSON.stringify(user))
            commit('setCustomer', { token: payload.jwtToken, user: payload.user })

            if (!payload.user.customer.cart || !payload.user.customer.wishlist) {
                let userRenewRes = await API.get(`/custom/customer/${payload.user.customer.id}/revalidate-customer-validation`)
                user.user = userRenewRes.data
                // console.log('user res data : ', userRenewRes)
                // console.log('user data : ', user)

                //reset cookies & state
                // cookies.set('session-customer-auth', user)
                localStorage.setItem('session-customer-auth', JSON.stringify(user))
                commit('setCustomer', { token: res.data.jwt, user: res.user })
            }

            //get the customer cart & set it to localstorage
            let cartRes = await API.get(`/carts/${user.user.customer.cart}`)
            let cartData = { items: [], discount_code: null, cart_id: user.user.customer.cart }
            cartRes.data.item.forEach(item => {
                cartData.items.push({
                    product: item.product,
                    quantity: item.quantity
                })
            })
            let wishlistRes = await API.get(`/wishlists/${user.user.customer.wishlist}`)
            let wishlistData = { items: [], wishlist_id: user.user.customer.wishlist }
            wishlistRes.data.item.forEach(item => {
                wishlistData.items.push({
                    product: item.product
                })
            })
            localStorage.setItem('cart', JSON.stringify(cartData))
            localStorage.setItem('wishlist', JSON.stringify(wishlistData))

            localStorage.setItem('selangkah-data', JSON.stringify(payload.selangkahData))

            //redirect to specific page
            switch (payload.redirectAfter) {
                case 'main':
                    return window.location.href = '/'
                case 'home_nursing':
                    return window.location.href = '/home-nursing/booking'
                case 'telemedicine':
                    return window.location.href = '/e-prescription/user-profile'
                case 'clinic_appointment':
                    return window.location.href = '/clinic-appointment/manage'
            
                default:
                    return window.location.href = '/';
            }
        },
        signOut() {
            // cookies.remove('session-customer-auth')
            localStorage.removeItem('session-customer-auth')
            let cartData = { items: [], discount_code: null, cart_id: null }
            localStorage.setItem('cart', JSON.stringify(cartData))
            localStorage.removeItem('wishlist')
            localStorage.removeItem('consultation')

            //check (for now) whether it is for booking or not.
            // console.log('path? ', window.location.pathname)
            if (window.location.pathname.includes('/home-nursing/booking'))
                window.location.href = '/auth/login?redirect=booking'
            else
                window.location.href = '/auth/login'
        },
        forgotPassword({ commit }, payload) {
            commit('clearSuccess')
            commit('clearError')
            commit('setAuthProcessing', true)

            let data2send = {
                email: payload.email,
            }

            API.authenticate_forgot(data2send)
                .then(res => {
                    //give the success flag
                    commit('setSuccess')
                    // window.location.href = '/auth/forgot-password?email_send_status=success'
                }).catch(err => {
                    commit('setError', err.message)
                    //give the unsuccess flag
                    // window.location.href = '/auth/forgot-password?email_send_status=failed'
                    // setTimeout(() => commit('clearError'), 5000)
                })

        },
        resetPassword({ commit }, payload) {
            commit('clearSuccess')
            commit('clearError')
            commit('setAuthProcessing', true)

            let data2send = {
                code: payload.code,
                password: payload.password,
                passwordConfirmation: payload.passwordConfirmation,
            }

            // console.log('data2send : ', data2send)
            API.authenticate_reset(data2send)
                .then(res => {
                    //give the success flag
                    commit('setSuccess')
                    // var user = { user: { token: res.data.jwt, user: res.data.user } }
                    // cookies.set('session-customer-auth',user)
                    // commit('setCustomer', { token: res.token, user: res.user })

                    // //redirect to the main page
                    // window.location.href = '/'
                }).catch(err => {
                    cookies.remove('session-customer-auth')
                    commit('setError', err.message)
                    // setTimeout(() => commit('clearError'), 5000)
                })

        },
        providerHandler({ commit }, payload) {
            //make link of user with customer & cart if not yet exist.
            if (!payload.user.customer && !payload.user.cart) {
                //create relation
                API.get(`/custom/user/${payload.user.id}/create-customer-relation`)
                    .then(resUser => {
                        var user = { token: payload.jwt, user: resUser.data }
                        cookies.set('session-customer-auth', user)
                        commit('setCustomer', { token: payload.jwt, user: resUser.data })

                        //set cart_id to the customer
                        let cartData
                        if (localStorage.getItem('cart')) {
                            cartData = JSON.parse(localStorage.getItem('cart'))
                            cartData.cart_id = resUser.data.customer.cart
                        } else cartData = { items: [], discount_code: null, cart_id: resUser.data.customer.cart }
                        localStorage.setItem('cart', JSON.stringify(cartData))

                        //redirect to the main page
                        window.location.href = '/'
                    })
                    .catch(err => console.error('err : ', err))

            } else {//proceed with set auth data at browser storage
                var user = { token: payload.jwt, user: payload.user }
                cookies.set('session-customer-auth', user)
                commit('setCustomer', { token: payload.jwt, user: payload.user })

                //get the customer cart & set it to localstorage
                API.get(`/carts/${payload.user.customer.cart}`)
                    .then(cartRes => {
                        let cartData = { items: [], discount_code: null, cart_id: payload.user.customer.cart }
                        cartRes.data.item.forEach(item => {
                            cartData.items.push({
                                product: item.product,
                                quantity: item.quantity
                            })
                        })
                        console.log('yeahh')
                        localStorage.setItem('cart', JSON.stringify(cartData))

                        //redirect to the main page
                        window.location.href = '/'
                    })
                    .catch(err => console.error('err : ', err))
            }
        },
        updateCustomerShippingAddress({ commit, getters }, payload) {
            var token = getters.currentCustomer.token
            var user = getters.currentCustomer.user
            user.customer.shipping_addresses = null
            user.customer.shipping_addresses = payload.shipping_addresses
            user.customer.default_shipping_address_id = null
            user.customer.default_shipping_address_id = payload.default_shipping_address_id
            cookies.set('session-customer-auth', { token, user })
            commit('setCustomer', { token, user })
        },
        clearAllErrors({ commit }){
            commit('clearError')
        }
    }
}