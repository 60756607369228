import API from '../../utils/API'
import moment from 'moment'

export default {
    state: {
        promotions: null
    },
    getters: {
        promotions: state => state.promotions
    },
    mutations: {
        resyncPromo(state, payload){
            //1. refetch from API
            API.get('/promotions?is_active=true')
                .then(res => {
                    let promoData = res.data
                    if(promoData.length == 0) state.promotions = [] //means there is no promo
                    else {
                        // state.promotions = promoData
                        //filter with datetime first.
                        promoData = promoData.filter(promo => {
                            if(moment().diff(moment(promo.start_datetime)) >= 0 && moment(promo.end_datetime).diff(moment()) >= 0 ){
                                return promo //get the promo
                            }
                        })
                        state.promotions = promoData
                    }
                })
        }
    },
    actions: {
    }
}