import API from '../../utils/API'
import cookies from 'vue-cookies'
import moment from 'moment'

export default {
    state: {
        consultation: localStorage.getItem('consultation') ? JSON.parse(localStorage.getItem('consultation')) : null,
    },
    getters: {
        consultation: state => state.consultation,
    },
    mutations: {
        setConsultation(state, payload) {
            state.consultation = payload
            localStorage.setItem('consultation', JSON.stringify(state.consultation))
        },
    },
    actions: {
        async setNewConsultation({ commit, getters }, payload) {


            let consultationName = 'Consultation from '
            if (getters.currentCustomer.user.customer.full_name) consultationName += getters.currentCustomer.user.customer.full_name
            else consultationName += getters.currentCustomer.user.username

            let data2sendConsultation = {
                // consultation_name: 'Consultation - ' + moment().format('hh:mm A'),
                consultation_name: consultationName,
                consultation_description: '',
                customer: getters.currentCustomer.user.customer.id,
                doctor: payload.doctorID,
                prescriptions: [],
                chat: null,
                status: 'pending'
            }

            let data2sendPrescription = {
                prescription_name: '#SCE-' + moment().format('YYMMDDHHmmss'),
                is_active: true,
                issued_on: moment(),
                expired_on: moment().add(30, 'days'),
                issued_by: null,
                customer: getters.currentCustomer.user.customer.id,
                doctor: payload.doctorID,
                activity_statuses: [
                    {
                        status: "prescription_status-waiting for approval",
                        dateTime: moment(),

                    },
                    {
                        status: "medical_status-unsupplied",
                        dateTime: moment(),
                    }
                ],
            }

            return new Promise(async (resolve, reject) => {
                if (getters.consultation) resolve(getters.consultation)

                const res = await API.post('/chats', {})
                data2sendConsultation.chat = res.data.id

                const res02 = await API.post('/prescriptions', data2sendPrescription)
                data2sendConsultation.prescriptions.push(res02.data.id)

                const res03 = await API.post('/consultations', data2sendConsultation)
                let newConsultation = res03.data

                localStorage.setItem('consultation', JSON.stringify(newConsultation))
                commit('setConsultation', newConsultation)
                resolve(newConsultation)
            })
        },
        async setActiveConsultation({ commit, getters }, payload) {
            let newItem = null
            return new Promise(async (resolve, reject) => {
                if (getters.consultation) resolve(getters.consultation)
                const responseConsultations = await API.get(`/consultations?_sort=createdAt:DESC`);

                let newdata = responseConsultations.data.filter(x => x.customer && x.customer.user == payload.userID)
                if (newdata.length > 0) {
                    if (newdata[0].status == 'completed') {
                        // const resConsultation = await API.get(
                        //     `/consultations/${newdata[0].id}`
                        // );
                        // newItem = resConsultation.data
                        // const resDoc = await API.get(`/doctors/${newdata[0].doctor.id}`)
                        // newItem = {
                        //     ...newItem,
                        //     doctor: {
                        //         ...resDoc.data,
                        //         corporate_organisation: resDoc.data.corporate_organisation.id
                        //     }
                        // }
                    } else if (newdata[0].status == 'accepted' || newdata[0].status == 'pending') {
                        // console.log('filterConsultationActive');
                        const resConsultation = await API.get(
                            `/consultations/${newdata[0].id}`
                        );
                        newItem = resConsultation.data

                        if (newdata[0].doctor) {
                            let corporate_id = !newdata[0].doctor.corporate_organisation.id ? newdata[0].doctor.corporate_organisation : newdata[0].doctor.corporate_organisation.id;
                            const resCor = await API.get(`/corporate-organisations/${corporate_id}`);
                            const resDoc = await API.get(`/doctors/${newdata[0].doctor.id}`)
                            newItem = {
                                ...newItem,
                                doctor: {
                                    ...resDoc.data,
                                    corporate_organisation: resCor.data
                                }
                            }
                        }
                    }
                }
                // localStorage.setItem(
                //     "consultation",
                //     JSON.stringify(newItem)
                // );
                commit('setConsultation', newItem)
                resolve(newItem)
            })
        }
    },
}