<template>
  <div id="app">

    <!-- Initiate Routing components -->
    <router-view />

    <!-- <div id="ad-modal" class="modal" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Vaksin Covid-19 (Sinovac)</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <img class="w-100" src="@/assets/sinovac.jpg" alt="" />
          </div>          
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {  
  mounted() {
    $('#ad-modal').modal({

    })
  }
}
</script>

<style>
/** Alter some vue datepicker styling  */
.vdp-datepicker__calendar-button {
  position: absolute;
  right: 10px;
  top: 7px;
}
</style>