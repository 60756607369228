import API from '../../utils/API'
const Swal = require('sweetalert2')

// remember this setting just for 
// so, need to check wether the customer is just a guest or login as existing user
// if, guest, just use localstorage 
// else, use Cart endpoint from the user.
export default {
    state: {
        cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : { items: [], discount_code: null, cart_id: null }, 
    },
    getters: {
        cart: state => state.cart,
        numberOfItems: state => {
            return state.cart.items.reduce(
                (accumulator, item) => accumulator + item.quantity,
                0
            )
        }
    },
    mutations: {
        addItem(state, payload){
            //Call Sweetalert
            const Toast = Swal.mixin({
                showConfirmButton: false,
                timer: 1500,
                // timerProgressBar: true
            })
            Toast.fire({
                imageUrl: '/img/basket-trolley-small.gif',
                title: 'Item added'
            })


            const item = state.cart.items.find(i => i.product.id == payload.product.id)
            API.get(`/products/${payload.product.id}`)
                .then(resProd => {
                    if(!item) {
                        state.cart.items.push({
                            product: resProd.data,
                            quantity: payload.quantity
                        })
                    } else {
                        item.quantity += payload.quantity
                    }
        
                    localStorage.setItem('cart', JSON.stringify(state.cart))
                    
                    //THIS IS REDUNDANT LOGICS, MODIFY IT SOON TO AVOID REDUNDANCY
                    //update cart to server if user cart_id exist (customer exist)
                    if(state.cart.cart_id) {
                        let newCart = { item: [], discount_code: state.cart.discount_code, id: state.cart.cart_id }
                        state.cart.items.forEach(item => {
                            newCart.item.push({
                                product: item.product.id,
                                quantity: item.quantity
                            })
                        })
                        API.put(`/carts/${state.cart.cart_id}`, newCart)
                    }
                })
        },
        removeItem(state, payload){
            const index = state.cart.items.findIndex(i => i.product.id == payload.id)
            state.cart.items.splice(index, 1)
            localStorage.setItem('cart', JSON.stringify(state.cart))

            //THIS IS REDUNDANT LOGICS, MODIFY IT SOON TO AVOID REDUNDANCY
            //update cart to server if user cart_id exist (customer exist)
            if(state.cart.cart_id) {
                let newCart = { item: [], discount_code: state.cart.discount_code, id: state.cart.cart_id }
                state.cart.items.forEach(item => {
                    newCart.item.push({
                        product: item.product.id,
                        quantity: item.quantity
                    })
                })
                API.put(`/carts/${state.cart.cart_id}`, newCart)
            }
        },
        resetCart(state, payload){
            //once some operation has been done, do something with cart.
            //like right now, save to localstorage if guest. Else at server.. etc.
            localStorage.setItem('cart', JSON.stringify(state.cart))

            //THIS IS REDUNDANT LOGICS, MODIFY IT SOON TO AVOID REDUNDANCY
            //update cart to server if user cart_id exist (customer exist)
            if(state.cart.cart_id) {
                let newCart = { item: [], discount_code: state.cart.discount_code, id: state.cart.cart_id }
                state.cart.items.forEach(item => {
                    newCart.item.push({
                        product: item.product.id,
                        quantity: item.quantity
                    })
                })
                API.put(`/carts/${state.cart.cart_id}`, newCart)
            }
        }
    },
    actions: {
        // just action for
        // Function for generate something, like Excel pdf n so on.
        // Upload & Retrieve file upload function.
    }
}