import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import * as Sentry from "@sentry/vue"
// import { Integrations } from "@sentry/tracing"
import VCalendar from 'v-calendar'

Vue.use(VCalendar, {})

Vue.config.productionTip = false


// // Init Sentry.io 
// let isSentryEnabled = (process.env.VUE_APP_SENTRY_ISENABLED === 'true')
// let origins = (process.env.VUE_APP_SENTRY_ENABLED_DOMAINS).split(',')
// origins.push('/^\//')

// if(isSentryEnabled){
//   Sentry.init({
//     Vue,
//     dsn: process.env.VUE_APP_SENTRY_DSN,
//     integrations: [
//       new Integrations.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: origins,
//       }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
