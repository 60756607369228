import Vue from 'vue'
import Vuex from 'vuex'

import cart from './modules/cart'
import wishlist from './modules/wishlist'
import checkout from './modules/checkout'
import customer from './modules/customer'
import promotion from './modules/promotion'
import nurseBooking from './modules/nurse-booking'
import ePrescription from './modules/e-prescription'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cart,
    wishlist,
    customer,
    checkout,
    promotion,
    nurseBooking,
    ePrescription
  }
})
